<template>
    <!--    <transition name="fade" @after-enter="handleAfterEnter">-->
    <!--        <div class="notification" :style="style" v-show="visible">-->
    <!--      <span class="notification__content">-->
    <!--        {{content}}-->
    <!--      </span>-->
    <!--            <span class="notification__btn" @click="handleClose">{{btn}}</span>-->
    <!--        </div>-->
    <!--    </transition>-->
    <transition name="fade">
        <div class="notify-box" :class="'notify-'+type" v-show="visible">
            <div class="mask"></div>
            <div class="notify-content">
                <div class="notify-inner">
                    <i class="close-icon el-icon-close" @click="handleClose" v-if="type=='warning'"></i>
                    <i :class="'el-icon-'+type"></i>
                    <div>{{type=='success'?'操作成功':'操作失败'}}</div>
                    <div class="warning-tip" v-if="type!='success' && content">{{content}}</div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: 'Notification',
        props: {
            type: {
                type: String,
                default: "warning"
            },
            content: {
                type: String,
                default: ''
            },
            btn: {
                type: String,
                default: '关闭'
            },
            callFun:{
                type:Function
            }
        }
    }
</script>
<style lang="scss" scoped>
    .notify-box, .mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
    
    .notify-box {
        
        .mask {
            background-color: rgba(0, 0, 0, 0.5);
        }
        
        .notify-content {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px 0px rgba(188, 188, 188, 0.38);
            border-radius: 2px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
            text-align: center;
            box-sizing: border-box;
        }
        
        [class*="el-icon-"] {
            font-size: 27px;
        }
        
        .notify-inner {
            position: relative;
            font-size: 14px;
        }
        
        .close-icon {
            font-size: 18px;
            position: absolute;
            top: 12px;
            right: 12px;
            color: $color-999;
            @include pointer;
        }
        
        &.notify-success {
            .notify-inner {
                padding: 38px 0 32px;
                width: 260px;
            }
            
            .el-icon-success {
                color: rgba(80, 185, 29, 1)
            }
        }
        
        &.notify-warning {
            .notify-inner {
                width: 280px;
                padding: 32px 35px 25px;
            }
            
            .el-icon-warning {
                font-size: 27px;
                color: $warn-color;
            }
            
            .warning-tip {
                font-size: 12px;
                color: #999999;
                line-height: 16px;
                margin-top: 4px;
            }
        }
    }
    
    .fade-enter-active, .fade-leave-active {
        transition: opacity 500ms;
    }
    
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    
    .notification {
        display: flex;
        background-color: #303030;
        color: rgba(255, 255, 255, 1);
        align-items: center;
        padding: 20px;
        position: fixed;
        min-width: 280px;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.3);
        flex-wrap: wrap;
        transition: all 0.3s;
        
        &__content {
            padding: 0;
        }
        
        &__btn {
            color: #ff4081;
            padding-left: 24px;
            margin-left: auto;
            cursor: pointer;
        }
    }
</style>

