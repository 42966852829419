import Vue from 'vue'
import Notification from './notification'

const NotificationConstructor = Vue.extend(Notification)

const instances = []
let seed = 1
const removeInstance = (instance) => {
    if (!instance) return
    const len = instances.length
    const index = instances.findIndex(ins => instance.id === ins.id)
    instances.splice(index, 1)
    if (len <= 1) return
    // const removeHeight = instance.height
    // for (let i = index; i < len - 1; i++) {
    //     instances[i].verticalOffset = parseInt(instances[i].verticalOffset) - removeHeight - 16
    // }
}
const notify = (options = {}) => {
    if (Vue.prototype.$isServer) return
    // 获取vue实例
    let instance = new NotificationConstructor({
        propsData: options,
        data() {
            return {
                // verticalOffset: 0,
                timer: null,
                visible: false,
                height: 0
            }
        },
        // computed: {
        //     style() {
        //         return {
        //             position: 'fixed',
        //             right: '20px',
        //             bottom: `${this.verticalOffset}px`
        //         }
        //     }
        // },
        mounted() {
            this.createTimer()
            // this.$el.addEventListener('mouseenter', () => {
            //     if (this.timer) {
            //         this.clearTimer(this.timer)
            //     }
            // })
            // this.$el.addEventListener('mouseleave', () => {
            //     if (this.timer) {
            //         this.clearTimer(this.timer)
            //     }
            //     this.createTimer()
            // })
        },
        updated() {
            // this.height = this.$el.offsetHeight
        },
        beforeDestroy() {
            this.clearTimer()
        },
        methods: {
            createTimer() {
                if(options.type=='success'){
                    this.timer = setTimeout(() => {
                        this.visible = false
                        document.body.removeChild(this.$el)
                        removeInstance(this)
                        this.$destroy()
                        if(options.callFun)  options.callFun();
                    }, options.timeout||1000)
                }else{
                    this.timer = setTimeout(() => {
                        this.visible = false
                        document.body.removeChild(this.$el)
                        removeInstance(this)
                        this.$destroy();
                        
                        if(options.callFun)  options.callFun();
                    }, options.timeout || 5000)
                }
                
            },
            clearTimer() {
                if (this.timer) {
                    clearTimeout(this.timer)
                }
            },
            handleClose() {
                this.visible = false
                document.body.removeChild(this.$el)
                removeInstance(this)
                this.$destroy(true);
                if(options.callFun)  options.callFun();
               
            },
        }
    })
    
    const id = `notification_${seed++}`
    instance.id = id
    // 生成vue中的$el
    instance = instance.$mount()
    // 将$el中的内容插入dom节点中去
    document.body.appendChild(instance.$el)
    instance.visible = true
    
    // eslint-disable-next-line no-unused-vars
    // let verticalOffset = 0
    //
    // instances.forEach(item => {
    //     verticalOffset += item.$el.offsetHeight + 16
    // })
    //
    // verticalOffset += 16
    // instance.verticalOffset = verticalOffset
    //
    instances.push(instance)
    
    return instance
}

export default notify

