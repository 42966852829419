import axios from './axios'

let instance = axios()

/**
 * 设置请求域名
 * @param {String} path 路径
 * @param {String} type 类型
 */
const initPath = (path, type) => {
  let _path = ''
  switch (type) {
    case 'SEAL': _path = process.env.VUE_APP_URL + path // 企业文化
      break
    case 'PORTAL': _path = process.env.VUE_APP_PORTAL_URL + path // 门户权限
      break
  }
  return _path
}

export default function (type = 'SEAL') {
  return {
    get(url, params = {}, headers, other) {
      let options = {}

      if (params) {
        options.params = params
      }
      if (headers) {
        options.headers = headers
      }
      if (other) {
        options.other = other
      }
      return instance.get(initPath(url, type), options)
    },
    post(url, params = {}, headers, other) {
      let options = {}

      if (headers) {
        options.headers = headers
      }
      if (other) {
        options.other = other
      }

      return instance.post(initPath(url, type), params, options)
    },
    put(url, params = {}, headers, other) {
      let options = {}
      if (headers) {
        options.headers = headers
      }
      if (other) {
        options.other = other
      }

      return instance.put(initPath(url, type), params, options)
    },
    delete(url, params = {}, headers, other) {
      let options = {}
      if (params) {
        options.params = params
      }
      if (headers) {
        options.headers = headers
      }
      if (other) {
        options.other = other
      }

      return instance.delete(initPath(url, type), options)
    }
  }
}
