import http from '../index'
const api = http('PORTAL')
let header = {
}
export default {
  /**
   * 经营合同数据
   */
  getIndexData() {
    return api.get(`api/screen/contract/resource/index`, {}, header)
  },
  /**
   * 经营监控-资源数据
   */
  getModule() {
    return api.get(`api/screen/contract/resource/module`, {}, header)
  },
  /**
   * 经营监控-地区数据
   */
  getRegion() {
    return api.get(`api/screen/contract/resource/region`, {}, header)
  },
}
