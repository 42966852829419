
// 保存当前菜单栏的路径
export default {
    SETUSERINFO(state, data = null) {
        state.userInfo = data
    },
    SETMSGDIALOG(state, data = null) {
        state.msgDialog = data
    },
    SETPWDDIALOG(state, data = false) {
        state.pwdDialog = data
    },
    SETENTERPRISE(state, data = []) {
        state.enterpriseList = data || []
    },
    SET_MSG_UNREAD_NUM(state, data) {
        state.msgUnreadNum = data
    }
}
