(function(win,doc){
  var docEl = doc.documentElement,
      design = 1024,
      rootValue = 100;
  var resizeEvt = "orientationchange" in win ? "orientationchange" : "resize";
  var recale = function () {
    if (doc.documentElement.clientWidth <= 1024) {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      docEl.style.fontSize = rootValue * (clientWidth / design) + "px";
    } else {
      // 超过平板设置的尺寸值设置为默认大小，element ui的icon是拿这个值设置的（pc端单位都是px）
      docEl.style.fontSize = "14px";
    }
  }
  if(!doc.addEventListener) return;
  win.addEventListener(resizeEvt,recale,false);
  docEl.addEventListener("DOMContentLoaded",recale,false);
  recale(); 
})(window,document)
