import http from '../index'
const api = http()
let header = {
}
export default {
  /**
   * 稿件-统计
   */
  getArticleStatist() {
    return api.get(`/article/rss/statistics-article`, {}, header)
  },
  /**
   * 稿件-账号排名
   */
  getAccountRank() {
    return api.get(`/article/rss/rank-article`, {}, header)
  },
  /**
   * 稿件-账号文章
   */
  getArticlePage(params) {
    return api.get(`/article/rss/account-article`, params, header)
  },
  /**
 * 流量-统计
 */
  getStatist() {
    return api.get(`/article/rss/statistics-pv`, {}, header)
  },
  /**
   * 流量-账号排名
   */
  getRank() {
    return api.get(`/article/rss/rank-pv`, {}, header)
  },
  /**
   * 流量-趋势内容
   */
  getTrendArticlePage(params) {
    return api.get(`/article/rss/trend-article`, params, header)
  },
  /**
   * 流量-年度爆款排行
   */
  getHotArticlePage(params) {
    return api.get(`/article/rss/hot-article`, params, header)
  },
}
