import api from '../index'

let header = {
}
export default {
    /**
     * 竞品列表
     * @param {object} params  { page, perPage }
     * @param {string} type peer/hot/relation 竞品关注/热榜/全网链接
     */
    getPeerList(params, type = 'peer'){
        return api.get(`/analysis/${type}`, params, header)
    },
    /**
     * 词云数据
     */
    getWordle(type = 'peer'){
        return api.get(`/analysis/${type}/wordle`, {}, header)
    },
    /**
     * 排名统计
     */
    getRank(type = 'peer'){
        return api.get(`/analysis/${type}/rank`, {}, header)
    },
    /**
     * 时间段统计
     */
    getContrast(type = 'peer'){
        return api.get(`/analysis/${type}/contrast`, {}, header)
    },
}
