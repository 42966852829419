import QRCode from 'qrcode'
import { divide, round } from 'lodash'

export default {
  /**
   * 转成万元单位数值
   * @param {number} data 转换的值
   * @param {number} count 保留多少位小数点 
   * @returns 
   */
  transToTenthousand(data, count = 2) {
    return round(divide(data, 10000), count)
  },
  getNumTrans(num, isInt = false) {
    if (num === 0) return 0
    if (!num) return ''
    // 每三位加逗号
    if (isInt) {
      num = num.toFixed(0)
    } else {
      num = num.toString()
    }

    let str = "";
    let numArr = num.split('.')
    num = numArr[0] // 整数部分
    if (num.length > 3) {
      let arr = num.split("").reverse();
      for (let i = 0; i < arr.length; i++) {
        str = arr[i] + str
        if ((i + 1) % 3 == 0 && i !== arr.length - 1) {
          str = "," + str;
        }
      }
    } else {
      str = num
    }
    if (numArr[1]) str += '.' + numArr[1]  //小数点部分

    return str
  },

  //加
  floatAdd(arg1, arg2) {
    var r1, r2, m;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  },

  //减
  floatSub(arg1, arg2) {
    var r1, r2, m, n;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  },
  //乘
  floatMul(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) {console.log(e) }
    try { m += s2.split(".")[1].length } catch (e) {console.log(e) }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
  },

  //除
  floatDiv(arg1, arg2) {
    var t1 = 0, t2 = 0, r1, r2;
    try { t1 = arg1.toString().split(".")[1].length } catch (e) {console.log(e) }
    try { t2 = arg2.toString().split(".")[1].length } catch (e) {console.log(e) }

    r1 = Number(arg1.toString().replace(".", ""));

    r2 = Number(arg2.toString().replace(".", ""));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  numberToSplit(num) {
    return num.toString().replace(/\d+/, function (n) {
      return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    })
  },

  /**
   * 万位数字转为带w的字符串
   * @param {Number} data 
   */
  toTenthousand(data) {
    if (data < 10000) return data
    return (Math.floor(data / 10000) + (data % 10000 / 10000)).toFixed(1) + 'w'
  },

  /**
   * 数字转单位
   * @param {Number} num 
   */
  changeNumberUnit(num) {
    if (num >= 100000000) {
      return num / 100000000 + "亿";
    } else if (num >= 10000000) {
      return num / 10000000 + "千万";
    } else if (num >= 1000000) {
      return num / 1000000 + "百万";
    } else if (num >= 100000) {
      return num / 100000 + "十万";
    } else if (num >= 10000) {
      return num / 10000 + "万";
    } else {
      return num;
    }
  },

  /**
   * 判断是否是图片
   * @param {String} src 
   */
  isImg(src) {
    const imgReg = /\.(jpg|jpeg|png|gif|bmp|webp)$/i
    return imgReg.test(src)
  },

  /**
   * 延迟执行
   * @param {Number} times 
   * @returns 
   */
  sleep(times) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, times)
    })
  },

  /**
   *  @param date Date  日期对象
   *  @param fmt  string  指定格式(默认格式yyyy-mm-dd HH:MM:SS) 
   *  @returns string 
   */
  dateFormat(date = new Date(), fmt = 'yyyy-mm-dd HH:MM:SS') {
    let ret
    const opt = {
      "y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      }
    }
    return fmt;
  },

  /** 
  * 获取二维码
  * @param url 
  * @param opts qrcode 配置
  */
  getQRCode(url, opts) {
    return new Promise((res) => {
      const _opts = Object.assign({ errorCorrectionLevel: "H" }, opts)
      QRCode.toDataURL(url, _opts)
        .then(code => {
          res(code)
        })
    })
  }
}
