// 兼容
import '@babel/polyfill'
import Vue from 'vue'
import ElementUI from 'element-ui';
import Api from '@/api/install'
import Notification from './components/notification'
import * as echarts from 'echarts';
import utils from './assets/javascripts/utils';
import VueVirtualScroller from "vue-virtual-scroller"
// import './assets/style/element-ui.scss';
require('@/assets/javascripts/rem.js')

import App from './App.vue'
import router from './router'
import store from './store'
Vue.use(ElementUI);
Vue.use(Api)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;

Vue.use(Notification)
Vue.use(VueVirtualScroller)
// router.beforeEach((to, from, next) => {
  // const token = localStorage.getItem('Authorization')
  // const eid = localStorage.getItem('eid')

  // if (['/login'].includes(to.path)) {
  //   if (token) {
  //     next('/')
  //   } else {
  //     next()
  //   }
  // } else if (!token) {
  //   next('/login')
  // } else {
  //   if ((!store.state.enterpriseList || !store.state.enterpriseList.length) && eid) {
  //     Vue.prototype.$api.common.getEnterpriseByUser().then(res => {
  //       store.commit('SETENTERPRISE', res)
  //     })
  //   }
  //   next()
  // }
// })

Vue.filter('numTrans', utils.getNumTrans)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
