import axios from 'axios'
import qs from 'qs'
// import { Loading } from 'element-ui'
import {BASE_URL} from './config'
// import router from '../router'
// import store from '../store' //store
import notify from "../components/notification/notify"

// 创建 axios 实例
let http = axios.create({
    // headers: {'Content-Type': 'application/json'},
    baseURL: BASE_URL,
    // timeout: 10000
})

// let loading
// let loadingCount = 0 // loading
let requestUrls = [] // 正在请求中的接口

// 请求开始处理函数
const _startOfRequest = (url) => {
    requestUrls = requestUrls.concat(url)
}

// 请求结束处理函数
const _endOfRequest = (url) => {
    if (!url) {
        requestUrls = []
        return
    }

    requestUrls = requestUrls.filter(v => v !== url)
}

// 设置 delete、post、put 默认 Content-Type
http.defaults.headers.post['Content-Type'] = 'application/json'
http.defaults.headers.put['Content-Type'] = 'application/json'
// 添加请求拦截器
http.interceptors.request.use(config => {
    const Authorization = localStorage.getItem('Authorization')


    const url = `${config.method}_${config.url}`

    // if (requestUrls.includes(url)) {
    //     throw new Error('repeat url')
    // }

    _startOfRequest(url)

    if(Authorization){
        config.headers['Authorization'] = Authorization
    }

    if( config.headers.noAuth ){
        delete config.headers['Authorization']
        delete config.headers.noAuth;
    }

    if (config.method === 'post' || config.method === 'put') {
        // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
        if (config.headers.post['Content-Type'] == "application/x-www-form-urlencoded") {
            let data = config.data;
            config.data = qs.stringify(data);//对参数进行序列化
        } else if (config.headers.post['Content-Type'] == "application/json") {
            config.data = JSON.stringify(config.data)
        }
    } else if (config.method === 'get') {
        // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
        config.params = Object.assign({}, config.params, {
            '_': Date.now()
        })
    } else if (config.method === 'delete') {
        config.data = JSON.stringify(config.params)
    }
    // 请求发送前进行处理

    return config
}, error => {
    // 请求错误处理
    notify({
        content: "请求失败",
        type: 'warning'
    })

    return Promise.reject(error)
})

// 添加响应拦截器
//之前用状态码用的数字类型，现版本改为字符串类型，因为状态码可能带有字符标识
http.interceptors.response.use(response => {
    _endOfRequest(`${response.config.method}_${response.config.url}`)

    let { data } = response

    if (data.status === 'success' || data.success) {
        return data.data
    } else {
        // if (![424021, 424009,300601, 300602, 300603, 300604, 300605, 400501, 400502].includes(data.code)) {
            notify({
                content: data.msg,
                type: 'warning'
            })
        // }
        return Promise.reject(data)
    }
}, error => {
    notify({
        content: "请求失败",
        type: 'warning'
    })
    const {response:{data}} = error;

    if (error.message === 'repeat url') {
        console.warn('重复请求')
        return
    }

    _endOfRequest()

    // try{
    //     let belong = error.response.config.headers.belong;
    //     if( belong == "schedule" ){
    //         if( data.status !== 503 ){
    //             notify({
    //                 content: "请求失败",
    //                 type: 'warning'
    //             })
    //         }
    //     }else{
    //         notify({
    //             content: "请求失败",
    //             type: 'warning'
    //         })
    //     }
    // }catch(e){
    //     notify({
    //         content: "请求失败",
    //         type: 'warning'
    //     })
    // }

    return Promise.reject(error)
})

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
    return http
}
