<template>
    <div id="app">
        <router-view v-if="isRouterAlive" />
    </div>
</template>

<script>
export default {
    provide () {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isRouterAlive: true
        }
    },
    created() {
        window.addEventListener('resize', () => {
            window.location.reload()
        })
    },
    methods: {
        reload () {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        }
    }
}
</script>

<style lang="scss">
body, div, span, header, footer, nav, section, aside, article, ul, dl, dt, dd, li, a, p, h1, h2, h3, h4, h5, h6, i, b, textarea, input, select, figure, figcaption, button {
    padding: 0;
    margin: 0;
    list-style: none;
    font-style: normal;
    text-decoration: none;
    border: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    font-family: PingFangSC-Regular, PingFangSC-MediumHelvetica, Avenir, Arial, sans-serif;
    &:focus {
        outline: none;
    }
}
#app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: PingFangSC-Regular, PingFangSC-MediumHelvetica, Avenir, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;

}
</style>
